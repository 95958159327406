#maincontent {
  display: block;
}

.cd-main-content {
  position: static;
  overflow: visible;
  float: none;
}

.diary-carousel {

  .owl-stage {
    width: 100%!important;

    .owl-item {
      float: none;
      display: block;

      .diary-exercise-planed {
        border-top: 1px dashed black;

        .diary-exercise-header {
          font-weight: bold;
        }
      }
    }
  }
}
